.screen {
  margin: 5vh var(--spacing-base);

  .content {
    margin-top: var(--spacing-2xl);
  }

  .topContent {
    display: flex;
    justify-content: space-between;
    margin-right: var(--spacing-2xl);
  }

  .addBtn {
    width: 10vw;
  }

  .table {
    margin: var(--spacing-base) auto;
    border-radius: var(--border-radius);
  }

  .expired {
    color: red;
  }

  .upload {
    margin: 16px;
  }
}

.input {
  margin-bottom: var(--spacing-base);
}

.facultySelect {
  margin-bottom: var(--spacing-sm);
}

.submit {
  margin-top: var(--spacing-2xl);
}

.danger {
  background-color: red;
}

.buttonRow {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: var(--spacing-2xl);

  .left{
    margin-right: 16px;
  }
}

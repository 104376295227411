* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.text {
  color: var(--primary);
  font-size: var(--font-lg);
}

:root {
  /* COLORS */
  --primary: #002a56;
  /* rgb(69, 135, 239); #4586ef */
  --primaryDark: #3151aa;
  --primary-10: rgba(69, 135, 239, 0.1);
  --primary-05: rgba(69, 135, 239, 0.05);
  --success: rgb(46, 176, 98); /* #2eb062 */
  --success-10: rgba(46, 176, 98, 0.1);
  --danger: rgb(250, 74, 87); /* #fa4a57 */
  --warning: rgb(245, 166, 35); /* #f5a523 */

  --gray: rgb(81, 94, 107); /* #515e6b */
  --gray-light: rgb(132, 146, 166); /* #8492a6 */
  --gray-lighter: rgb(151, 158, 166); /* #979ea6 */
  --gray-lightest: rgb(217, 217, 217); /* #d9d9d9 */
  --gray-lines: #d2d2d7;

  --offwhite-dark: rgb(234, 234, 234); /* ##eaeaea */
  --offwhite: rgb(241, 241, 241); /* #f1f1f1 */
  --offwhite-light: rgb(247, 247, 247); /* #f7f7f7 */

  --border-light: rgb(229, 233, 242); /* #e5e9f2 */
  --shadow: rgba(158, 158, 158, 0.5);

  --white: rgb(255, 255, 255); /* #ffffff */
  --black: rgb(1, 23, 56); /* #011738 */

  --true-black: rgba(0, 0, 0);
  --true-black-60: rgba(0, 0, 0, 0.6);
  --true-black-70: rgba(0, 0, 0, 0.7);
  --true-black-80: rgba(0, 0, 0, 0.8);

  /* FONT SIZES */
  --font-2xl: 30px;
  --font-xl: 24px;
  --font-lg: 20px;
  --font-md: 18px;
  --font-base: 16px;
  --font-sm: 14px;
  --font-xs: 12px;
  --font-2xs: 11px;

  /* SPACING */
  --spacing-3xs: 2px;
  --spacing-2xs: 4px;
  --spacing-xs: 8px;
  --spacing-sm: 12px;
  --spacing-base: 16px;
  --spacing-md: 20px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-2xl: 40px;
  --spacing-3xl: 56px;
  --spacing-4xl: 64px;
  --spacing-5xl: 80px;

  /* ICONS */
  --icon-3xs: 12px;
  --icon-2xs: 14px;
  --icon-xs: 16px;
  --icon-sm: 18px;
  --icon-base: 20px;
  --icon-md: 24px;
  --icon-lg: 32px;
  --icon-xl: 40px;
  --icon-2xl: 52px;

  /* TYPOGRAPHY */
  --font-bold: bold;
  --font-semibold: 600;
  --font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  /* BORDER */
  --border-radius: 8px;
  --border-solid-light: 1px solid var(--border-light);

  /* TRANSITIONS */
  --transition-short: 150ms;
  --transition-normal: 300ms;

  /* EASINGS */
  --ease-back: cubic-bezier(0.34, 1.56, 0.64, 1);

  /* WIDTH */
  --width-dashboard: 1280px;
}
